import Mark from 'mark.js'
import { computed, observable } from 'mobx'
import Router from 'next/router'
import pluralize from 'pluralize'

import { limit } from '../config/pagination'
import { $RootStore } from '../src/stores/RootStore'
import { $Filter } from '../types'
import { Sdk } from '../graphQL/index'

class ListViewModel {
  @observable loaded = false
  @observable items: Array<any> = []
  @observable filters: Array<any> = []
  @observable searchOptions = {}
  @observable sortBy = ''
  @observable sortDir = -1
  @observable search = ''
  @observable page = 0
  queryParams = {}
  collectionName = ''
  getQueryName: (keyof Sdk) | undefined
  store: $RootStore | undefined
  rootModel: any
  limit = limit

  // Navigates to create route for collection
  goToAddModel = (): void => {
    const pluralCollectionName = pluralize(this.collectionName)
    Router.push(`/${pluralCollectionName}/[id]`, `/${pluralCollectionName}/create`)
  }

  // Init the items and state
  loadState = async () => {
    const query = new URLSearchParams(window.location.search)
    this.filters.forEach((f: $Filter) => {
      const value = query.get(f.property)
      if (value !== undefined && value !== null) f.value = value
    })

    this.sortBy = query.get('sortBy') || this.sortBy
    this.sortDir = query.get('sortDir') ? parseInt(query.get('sortDir') || '', 10) : this.sortDir
    this.search = query.get('search') || ''

    await this.getItems()
  }

  // Sets the root model
  setRootModel(rootModel: any) {
    this.rootModel = rootModel
  }

  // Gets items from database
  getItems = async (clear = true): Promise<void> => {
    if (clear) this.items = []
    this.loaded = false

    try {
      const { communication } = this.store!
      const response: any = await communication.requester[this.getQueryName!]({ ...this.listQuery })
      this.loaded = true

      const newItems = response[this.getQueryName!]

      // If has pagination, concat results to items else just set items
      if (this.listQuery.pagination && this.listQuery.pagination.skip) {
        this.items = [...this.items, ...newItems]
      } else {
        this.items = newItems
      }

      const el = document.getElementById('customTable')
      if (el) {
        const mark = new Mark(el)
        if (this.search) {
          mark.unmark()
          mark.mark(this.search, {})
        } else {
          mark.unmark()
        }
      }
    } catch (e) {
      console.log(`Error ${this.getQueryName}`, e)
    }
  }

  // Change the sort
  changeSort = (property: string) => {
    if (property === this.sortBy) {
      this.sortDir = this.sortDir === -1 ? 1 : -1
    } else {
      this.sortBy = property
      this.sortDir = 1
    }

    this.updateUrl()
  }

  // Update search
  updateSearch = (search: string) => {
    this.page = 0
    this.search = search
    this.updateUrl()
  }

  // Change filter and then fetch items
  changeFilter = (property: string, value: string): void => {
    this.page = 0
    const filter = this.filters.find((f: $Filter) => f.property === property)
    filter.value = value
    this.updateUrl()
  }

  // When changing filter or search we want to change url and then loadState
  updateUrl = async () => {
    // Use filters to create url
    const currentTabParam = new URLSearchParams(window.location.search).get('tab')
    const query = currentTabParam ? { tab: currentTabParam, ...this.urlQuery } : this.urlQuery

    const params = new URLSearchParams(query).toString()
    const urlParts = Router.asPath.split('?')
    const rootPath = urlParts && urlParts.length > 0 ? urlParts[0] : Router.asPath

    Router.replace(`${Router.pathname}?${params}`, `${rootPath}?${params}`)
    this.getItems()
  }

  // Builds a query based on filter and search
  get listQuery(): any {
    let filter: any = {}

    this.filters.forEach((f: $Filter) => {
      if (f.value !== undefined && f.value !== null && f.value !== 'default') {
        const query: any = f.query(f.value)
        filter = { ...filter, ...query }
      }
    })

    const sort: any = {}

    if (this.sortBy) {
      sort[this.sortBy] = this.sortDir
    }

    return {
      filter,
      sort,
      search: this.search,
      pagination: { skip: this.page * this.limit, limit: this.limit },
    }
  }

  // Load more rows
  loadMore = async () => {
    this.page = this.page + 1
    this.getItems(false)
  }

  // Has more elements
  @computed get hasMoreRows() {
    return this.items.length > 0 && this.items.length % this.limit === 0
  }

  // Builds a url query based on filter and search
  @computed get urlQuery(): any {
    const urlQuery: any = {}

    this.filters.forEach((f: $Filter) => {
      if (f.value && f.value !== 'default') {
        urlQuery[f.property] = f.value
      }
    })

    if (this.sortBy) urlQuery.sortBy = this.sortBy
    if (this.sortDir) urlQuery.sortDir = this.sortDir
    if (this.search) urlQuery.search = this.search

    return urlQuery
  }
}

export default ListViewModel
